import { forEach, get } from 'lodash';
import { LogicProps } from '../types';

export const applyConditionalLogic = (
  logics: LogicProps[],
  attrkey: string,
  value: string,
) => {
  try {
    logics?.forEach((logic) => {
      const actionBlock = get(logic, 'actionBlock.label');
      const targetBlocks = get(logic, 'targetBlocks');
      const operation = get(logic, 'operation.value');
      const options = get(logic, 'actionBlockOptions', []);
      const option = options?.some((option) => option?.value === value);
      if (actionBlock === attrkey && targetBlocks.length > 0 && operation) {
        targetBlocks?.forEach((targetBlock) => {
          const targetElements = document.querySelectorAll(
            `[id="${targetBlock?.label}"], [id="${targetBlock?.label}_instance_conditional_logic"]`,
          );
          forEach(targetElements, (targetElement) => {
            const shouldHide = option
              ? operation === 'hide'
              : operation === 'show';
            (targetElement as HTMLElement).style.display = shouldHide
              ? 'none'
              : 'block';

            // Enable or disable the field based on the operation also the fields inside the section
            const inputElements = targetElement.querySelectorAll<
              HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
            >('input, select, textarea');
            inputElements?.forEach((input) => {
              input.disabled = shouldHide; // Disable if hiding, enable if showing
            });
          });
        });
      }
    });
  } catch (e) {
    console.error(e);
  }
};
