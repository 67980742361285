import { isDuplicate } from '@apps/form/src/utils';
import { EMAILREGEX } from '@apps/form/src/utils/BlockList/constants';
import { SingleBlockType } from '@apps/form/src/components/Block/types';

export const blockMenuList: SingleBlockType[] = [
  {
    id: 1,
    name: 'InputText',
    type: 'input',
    properties: {
      required: false,
      placeholder: 'Enter Input value',
    },
  },
  {
    id: 2,
    name: 'Number',
    type: 'number',
    properties: {
      required: false,
    },
  },
  {
    id: 2,
    name: 'Email',
    type: 'email',
    properties: {
      required: false,
      placeholder: 'example@domain.com',
    },
  },
  {
    id: 3,
    name: 'Checkbox',
    type: 'checkbox',
    properties: {
      options: [
        {
          id: 'option1',
          name: 'Option1',
          label: 'Option 1',
          value: 'option1',
        },
      ],
    },
  },
  {
    id: 4,
    name: 'Dropdown',
    type: 'select',
    properties: {
      options: [
        {
          id: 'option1',
          name: 'Option1',
          label: 'Option 1',
          value: 'option1',
        },
      ],
    },
  },
  {
    id: 5,
    name: 'Radio Button Group',
    type: 'radio',
    properties: {
      options: [
        {
          id: 'option1',
          name: 'Option1',
          label: 'Option 1',
          value: 'option1',
        },
      ],
    },
  },
  {
    id: 6,
    name: 'Text',
    type: 'text',
    properties: {
      label: 'Add your label',
      placeholder: 'Enter your text',
    },
  },
  {
    id: 7,
    name: 'Date Picker',
    type: 'datepicker',
    properties: {},
  },
  {
    id: 8,
    name: 'Text Area',
    type: 'textarea',
    properties: {
      value: '',
      placeholder: 'Add your Text Content',
    },
  },
  {
    id: 9,
    name: 'File',
    type: 'file',
    properties: {},
  },
];

export const validationBox: any = {
  input: {
    attrkey: (value: string) => value?.length > 0,
    minLength: (value: string) => !isNaN(+value) && +value > 0,
    maxLength: (value: string) => !isNaN(+value) && +value > 0,
  },
  number: {
    attrkey: (value: string) => value?.length > 0,
    value: (value: string) => !isNaN(+value),
    min: (value: string) => !isNaN(+value),
    max: (value: string) => !isNaN(+value),
  },
  email: {
    attrkey: (value: string) => value?.length > 0,
    value: (value: string) =>
      !!String(value).toLowerCase().trim().match(EMAILREGEX),
  },
  checkbox: {
    attrkey: (value: string) => value?.length > 0,
    options: (arr: any[]) => isDuplicate(arr),
  },
  select: {
    attrkey: (value: string) => value?.length > 0,
    options: (arr: any[]) => isDuplicate(arr),
  },
  radio: {
    attrkey: (value: string) => value?.length > 0,
    options: (arr: any[]) => isDuplicate(arr),
  },
  textarea: {
    attrkey: (value: string) => value?.length > 0,
    minLength: (value: string) => !isNaN(+value) && +value > 0,
    maxLength: (value: string) => !isNaN(+value) && +value > 0,
  },
  text: {
    attrkey: (value: string) => value?.length > 0,
  },
  datepicker: {
    attrkey: (value: string) => value?.length > 0,
  },
  file: {
    attrkey: (value: string) => value?.length > 0,
    size: (value: string) => !isNaN(+value) && +value > 0,
    fileTypes: (arr: any[]) => isDuplicate(arr),
  },
  image: {},
  photoCapture: { attrkey: (value: string) => value?.length > 0 },
  sectionStart: { attrkey: (value: string) => value?.length > 0 },
  button: {
    label: (value: string) => value?.length < 21,
  },
};
