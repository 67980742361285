import React, { useEffect, useRef } from 'react';
import { TextProps } from './types';

const Text = (props: TextProps) => {
  const { size, color, state } = props;
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const textAreaValue = props.value || '';
  // const [textAreaValue, setTextAreaValue] = useState(props.value);

  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      textAreaRef.current.style.height = '0px';
      const scrollHeight = textAreaRef.current.scrollHeight;
      textAreaRef.current.style.height = scrollHeight + 'px';
    }
  });

  return state === 'builder' ? (
    <textarea
      data-testid="text_textarea"
      ref={textAreaRef}
      value={textAreaValue}
      className={`${size?.value} outline-0 w-full resize-none px-[0.5rem] pt-[0.5rem] bg-white hover:cursor-pointer`}
      rows={1}
      style={{
        color: color,
      }}
      disabled={true}
      placeholder="Type your text"
    />
  ) : (
    <div
      className={`${size?.value} outline-0 w-full break-words resize-none hover:cursor-default`}
      data-testid="text_div"
    >
      {textAreaValue}
    </div>
  );
};

export const TextOptions: {
  label: string;
  value: 'text-3xl' | 'text-2xl' | 'text-xl' | 'text-base';
}[] = [
  { value: 'text-base', label: 'Paragraph' },
  { value: 'text-3xl', label: 'Heading 1' },
  { value: 'text-2xl', label: 'Heading 2' },
  { value: 'text-xl', label: 'Heading 3' },
];

export default Text;
