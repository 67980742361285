import React, { memo, useRef } from 'react';
import classNames from 'classnames';
import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetSelectFieldTypesQuery } from '@apps/form/src/store/services/updateFormBuilder';
import BlockNoteEditor from '@apps/form/src/components/BlockNoteEditor';
import MenuBox from '@apps/form/src/components/Popover/MenuPopover/MenuBox';
import { validationBox } from '@apps/form/src/components/Block/blockMenuList';
import { propertyBox } from '@apps/form/src/components/Block/PropertyBox';
import { fetchProperties } from '@apps/form/src/components/SideMenu';
import { FORM_TYPE } from '@apps/form/src/utils/constant';

import {
  BlockEditor,
  EditorInstance,
  FormBuilderProps,
  SelectFieldType,
  SelectFieldTypeQuery,
} from './types';

const FormBuilder: React.FC<FormBuilderProps> = ({
  id,
  updateForm,
  formContent,
  formType,
}) => {
  const editorRef = useRef<EditorInstance | null>(null);
  const { data } = (useGetSelectFieldTypesQuery({}) as {
    data: SelectFieldTypeQuery;
  }) || {
    data: { frm_select_field_type: [] },
  };

  const selectFieldTypes =
    data?.frm_select_field_type?.map((type: SelectFieldType) => ({
      label: type?.name,
      value: type?.name,
      id: type?.id,
    })) || [];

  const [showBtnMenu, setShowBtnMenu] = React.useState(false);
  const [btnProperties, setBtnProperties] = React.useState<any>(
    formContent?.button?.props || { label: '' },
  );
  const menuFieldsRef = useRef<any>(null);

  const formUpdate = (btnProps: any) => {
    const editor = editorRef?.current?.getBlockEditor() as BlockEditor;
    if (editor?.document) {
      updateForm({
        form_id: id,
        structure: {
          doc: editor.document,
          button: {
            id: 'registration_btn',
            type: 'button',
            props: { ...btnProps },
          },
        },
      });
    }
  };
  //env change
  return (
    <div className="mt-2 px-5 py-10 bg-white overflow-auto no-scrollbar">
      <BlockNoteEditor
        ref={editorRef}
        formContent={formContent?.doc || [{}]}
        isEditable
        onChange={() => {
          formUpdate(btnProperties);
        }}
        selectFieldTypes={selectFieldTypes}
      />

      <div
        className={classNames(
          'relative ml-[3.25rem]',
          formType !== FORM_TYPE.REGISTRATION && 'hidden',
        )}
      >
        <FontAwesomeIcon
          icon={faGear}
          className="text-gray-500 relative mr-2 cursor-pointer hover:text-gray-800"
          onClick={() => {
            if (showBtnMenu) {
              const properties = fetchProperties(menuFieldsRef.current);
              setBtnProperties({ ...btnProperties, ...properties });
              formUpdate(properties);
              menuFieldsRef.current = null;
            }
            setShowBtnMenu(!showBtnMenu);
          }}
        />
        <button
          id="submitButton"
          className="border rounded py-2 px-3 bg-blue-700 text-white text-sm font-medium text-center disabled"
        >
          {btnProperties?.label || 'Submit'}
        </button>
        {showBtnMenu && (
          <div className="w-80 rounded shadow-sm shadow-gray-300 border absolute -top-4 left-5">
            <MenuBox
              propertyBox={propertyBox['button']}
              validations={validationBox['button']}
              block={{ type: 'button', properties: btnProperties }}
              menuFieldsRef={menuFieldsRef}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FormBuilder);
