import axios from 'axios';

export const uploadFileToS3 = (
  presigned_url: string,
  file: string | Blob,
  id: number,
  onFileUploadSuccess: (id: number) => void,
  onFileUploadFailure: () => void,
) => {
  // Check if file is a data URL string
  if (typeof file === 'string' && file.startsWith('data:')) {
    // Extract MIME type
    const mimeString = file.split(',')[0].split(':')[1].split(';')[0];

    // Convert Base64 to Blob
    const byteString = atob(file.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });

    return axios
      .put(presigned_url, blob, {
        headers: {
          'Content-Type': mimeString,
        },
      })
      .then(() => onFileUploadSuccess(id))
      .catch(onFileUploadFailure);
  }

  // If it's already a blob or other type
  return axios
    .put(presigned_url, file)
    .then(() => onFileUploadSuccess(id))
    .catch(onFileUploadFailure);
};
