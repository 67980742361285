/**
 *
 * Input
 *
 */

import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toggle from '@apps/form/src/components/Popover/MenuPopover/ToggleSwitch';
import FieldLabel from '@apps/form/src/components/Tools/FieldLabel';
import {
  handleInputValidation,
  handleInvalidField,
} from '@apps/form/src/components/Tools/TriggerValidation/inputUtils';
import { VALIDATION_ERROR_MESSAGES } from '@apps/form/src/utils/constant';
import TriggerValidation from '@apps/form/src/components/Tools/TriggerValidation';

export const isValidRegex = (pattern: string): boolean => {
  try {
    new RegExp(pattern);
    return true;
  } catch {
    return false;
  }
};

export const Input = (props: any) => {
  const {
    label,
    tooltip,
    labelSize,
    help,
    error,
    hiddenLabel,
    name,
    type,
    value = '',
    defaultValue,
    placeholder,
    LeadingIcon,
    TrailingIcon,
    leadingAddon,
    trailingAddon,
    inlineAddon,
    autoFocus,
    hint,
    onChange,
    fieldEnableChange,
    onBlur,
    trailingIconOnClick,
    leadingIconOnClick,
    disabled,
    updatable,
    pre_filled,
    required,
    readOnly,
    onLabelChange,
    hasToggle,
    isEnabled,
    pattern,
    id,
    ...rest
  } = props;
  const inputRef = useRef<any>(null);
  const [inputValue, setInputValue] = useState<string>(value);
  const [isToggled, setIsToggled] = useState(isEnabled || false);
  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleInputValidation(event, setValidation);
  };

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    handleInvalidField(event, setValidation);
  };

  useEffect(() => {
    if (required && !inputValue)
      inputRef?.current?.setCustomValidity(VALIDATION_ERROR_MESSAGES.REQUIRED);
    else if (
      inputValue &&
      pattern?.label &&
      isValidRegex(pattern?.value) &&
      !inputRef.current?.value?.match(pattern?.value)
    )
      inputRef?.current?.setCustomValidity(pattern?.label);
    else inputRef?.current?.setCustomValidity('');
    setValidation((prev) => ({
      ...prev,
      message: inputRef?.current?.validationMessage,
    }));
  }, [inputValue]);

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  useLayoutEffect(() => {
    setIsToggled(isEnabled);
  }, [isEnabled]);

  return (
    <div className="relative gap-8">
      <div className="flex justify-between">
        {label && (
          <FieldLabel
            value={label}
            required={required}
            hiddenLabel={hiddenLabel}
            disabled={disabled}
            size={labelSize}
            id={id}
            tooltip={tooltip}
          >
            {label}
          </FieldLabel>
        )}
        {hasToggle && (
          <Toggle
            checked={isEnabled}
            onChange={(e: boolean) => {
              setIsToggled(e);
              fieldEnableChange && fieldEnableChange(e);
            }}
          />
        )}
        {hint && (
          <span className="text-sm text-gray-500" id={`${name}-hint`}>
            {hint}
          </span>
        )}
      </div>
      {(!hasToggle || (hasToggle && isToggled)) && (
        <>
          <div
            className={classNames(
              'flex relative rounded-md shadow-sm w-full max-w-[25rem]',
              (disabled || (!updatable && pre_filled)) && 'opacity-50',
            )}
          >
            {LeadingIcon && (
              <div
                className={classNames(
                  'absolute inset-y-0 left-0 pl-3 flex items-center',
                  leadingIconOnClick && !disabled ? 'cursor-pointer z-10' : '',
                  disabled ? 'cursor-not-allowed' : '',
                )}
                aria-hidden="true"
                onClick={leadingIconOnClick}
                data-testid="leading-input-icon"
              >
                <LeadingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            )}

            {inlineAddon && (
              <div
                className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                data-testid="input_inlineAddon"
              >
                <span className="text-gray-500 sm:text-sm">{inlineAddon}</span>
              </div>
            )}

            {leadingAddon && (
              <span
                className="inline-flex items-center px-3 rounded-l-md border border-r-0
             border-gray-300 bg-gray-50 text-gray-500 sm:text-sm"
              >
                {leadingAddon}
              </span>
            )}
            <div className="w-full">
              {!updatable && pre_filled ? (
                <span className="text-sm py-2 px-2 h-10 block">
                  {inputValue}
                </span>
              ) : (
                <input
                  {...rest}
                  id={id}
                  ref={inputRef}
                  type={type}
                  name={name}
                  required={required}
                  pattern={pattern?.value}
                  value={inputValue}
                  onChange={(e) => {
                    if (!readOnly) {
                      setInputValue(e.target.value);
                      onChange(e.target.value);
                    }
                  }}
                  onInvalid={handleInvalid}
                  onInput={handleInput}
                  onKeyDown={(e) =>
                    (disabled || (!updatable && pre_filled)) &&
                    e.preventDefault()
                  }
                  className={classNames(
                    'min-w-0 w-full px-2 border-solid border border-gray-200 rounded h-10 p-1 sm:text-sm focus:outline-none text-gray-900 bg-white',
                    LeadingIcon && 'pl-10',
                    TrailingIcon && 'pr-10',
                    error
                      ? 'focus:ring-rose-800 focus:border-rose-800 border-rose-800 text-gray-900 placeholder-rose-800'
                      : 'focus:ring-gray-500 focus:border-gray-500 border-gray-300 text-gray-900 placeholder-gray-300',
                    validation?.triggerInvalid &&
                      validation?.message &&
                      'border-red-700 rounded-md border',
                    readOnly && 'cursor-pointer',
                    leadingAddon ? 'rounded-r-md rounded-none' : 'rounded-md',
                    type === 'file' && 'group-hover:cursor-pointer pt-1.5',
                  )}
                  placeholder={placeholder}
                  aria-invalid="true"
                  aria-describedby={`${name}-error`}
                  tabIndex={0}
                  data-testid={rest['data-testid'] || 'input'}
                />
              )}
            </div>
            {trailingAddon && (
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={trailingIconOnClick}
              >
                <span className="text-gray-500 sm:text-sm">
                  {trailingAddon}
                </span>
              </div>
            )}
            {TrailingIcon && !error && (
              <div
                data-testid={`${name}-clear-button`}
                className={classNames(
                  'absolute inset-y-0 right-0 pr-3 flex items-center',
                  !trailingIconOnClick
                    ? 'pointer-events-none'
                    : 'z-10 cursor-pointer',
                )}
                aria-hidden="true"
                onClick={trailingIconOnClick}
              >
                <TrailingIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {help && (
            <p
              className="mt-2 text-sm text-gray-500"
              id={`${name}-description`}
            >
              {help}
            </p>
          )}
          {error && (
            <p
              className=" font-normal text-xs text-rose-800 mt-2 "
              id={`${name}-description`}
              data-testid="input_error_msg"
            >
              {error}
            </p>
          )}
          <TriggerValidation
            validationTrigger={validation?.triggerInvalid}
            validationMessage={validation?.message}
            name={name}
            alterClassName="mt-2"
          />
        </>
      )}
    </div>
  );
};

Input.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  labelSize: PropTypes.string,
  hiddenLabel: PropTypes.bool,
  LeadingIcon: PropTypes.object,
  TrailingIcon: PropTypes.object,
  value: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.string,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  inlineAddon: PropTypes.bool,
  disabled: PropTypes.bool,
  updatable: PropTypes.bool,
  pre_filled: PropTypes.bool,
  trailingAddon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  leadingAddon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hint: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  trailingIconOnClick: PropTypes.func,
  leadingIconOnClick: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onLabelChange: PropTypes.func,
  readOnly: PropTypes.any,
  hasToggle: PropTypes.bool,
  isEnabled: PropTypes.bool,
  fieldEnableChange: PropTypes.func,
  tooltip: PropTypes.string,
  type: PropTypes.oneOf([
    'email',
    'color',
    'number',
    'password',
    'url',
    'color',
    'text',
    'file',
  ]),
};

Input.defaultProps = {
  hiddenLabel: false,
  inlineAddon: false,
  type: 'text',
};

export default memo(Input);
