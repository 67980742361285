import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import MarkdownWrapper from '@apps/form/src/components/MarkdownWrapper';

interface TooltipProps {
  tooltipId: string;
  content: string;
}
// env update line
const Tooltip = ({ tooltipId, content }: TooltipProps) => (
  <>
    <InformationCircleIcon
      className="ml-2 w-4 h-4 flex-col justify-center items-center inline-flex text-neutral-400"
      data-tooltip-id={tooltipId || 'tooltip'}
    />
    <ReactTooltip
      id={tooltipId || 'tooltip'}
      place="top"
      className="z-10"
      delayHide={500}
      clickable={true}
    >
      <MarkdownWrapper>{content || ''}</MarkdownWrapper>
    </ReactTooltip>
  </>
);

export default Tooltip;
