import React from 'react';
import Loader from '@apps/form/src/components/Loader';
import { DeleteIcon } from '@apps/form/src/images/DeleteIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { UPLOAD_STATUS } from './constant';
import { FileUploadActionsProps } from './types';

const FileUploadActions: React.FC<FileUploadActionsProps> = ({
  uploadStatus,
  onClearFile,
}) => {
  const getFileTrailingIcon = () => {
    switch (uploadStatus) {
      case UPLOAD_STATUS.UPLOADING:
        return <Loader />;
      case UPLOAD_STATUS.SUCCESS:
        return <DeleteIcon />;
      case UPLOAD_STATUS.FAILED:
        return (
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="text-red-400"
          />
        );
      default:
        return null;
    }
  };

  return <div onClick={onClearFile}>{getFileTrailingIcon()}</div>;
};

export default FileUploadActions;
