import {
  TextOptions,
  errorType,
  layoutOptions,
} from '@apps/form/src/utils/BlockList/constants';
import { FileOptions } from '@apps/form/src/utils/BlockList/FileOptions';

const nameField = {
  name: 'Name*',
  key: 'attrkey',
  type: 'input',
  error: errorType.emptyName,
};

const labelField = {
  name: 'Label',
  key: 'label',
  type: 'input',
};

const requiredField = {
  name: 'Required',
  key: 'required',
  type: 'checkbox',
};

const disableField = {
  name: 'Disable Field',
  key: 'disabled',
  type: 'checkbox',
};

const tooltipField = {
  name: 'Tooltip',
  key: 'tooltip',
  type: 'input',
  hasToggle: true,
};

const placeholderField = {
  name: 'Placeholder',
  key: 'placeholder',
  type: 'input',
  hasToggle: true,
};

const layoutField = {
  name: 'Select Layout',
  key: 'layout',
  type: 'select',
  value: layoutOptions[0],
  options: layoutOptions,
};

const updatableField = {
  name: 'Updatable Field',
  key: 'updatable',
  type: 'checkbox',
};

// const faceDetectionField = {
//   name: 'Face Detection',
//   key: 'faceDetect',
//   type: 'checkbox',
// };

const rowSpanField = {
  name: 'Row Span',
  key: 'rowSpan',
  type: 'input',
  subType: 'number',
  hasToggle: true,
};

export const propertyBox: any = {
  input: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: 'Default Value',
      key: 'value',
      type: 'input',
      hasToggle: true,
    },
    {
      name: 'Minimum Length',
      key: 'minLength',
      type: 'input',
      error: errorType.invalidMinLength,
      hasToggle: true,
      subType: 'number',
    },
    {
      name: 'Maximum Length',
      key: 'maxLength',
      type: 'input',
      error: errorType.invalidMaxLength,
      hasToggle: true,
      subType: 'number',
    },
    {
      name: 'Regex',
      key: 'pattern',
      type: 'inputList',
      isSingle: true,
      hasToggle: true,
    },
    layoutField,
    rowSpanField,
  ],
  number: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: 'Default Value',
      key: 'value',
      type: 'input',
      error: errorType.invalidNumber,
      hasToggle: true,
      subType: 'number',
    },
    {
      name: 'Minimum Value',
      key: 'min',
      type: 'input',
      error: errorType.invalidMinValue,
      hasToggle: true,
      subType: 'number',
    },
    {
      name: 'Maximum Value',
      key: 'max',
      type: 'input',
      error: errorType.invalidMaxValue,
      hasToggle: true,
      subType: 'number',
    },
    layoutField,
    rowSpanField,
  ],
  email: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: 'Default Value',
      key: 'value',
      type: 'input',
      error: errorType.invalidEmail,
      hasToggle: true,
    },
    layoutField,
    rowSpanField,
  ],
  checkbox: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    {
      name: 'Checkboxes',
      key: 'options',
      type: 'inputList',
      error: errorType.invalidList,
    },
    layoutField,
    rowSpanField,
  ],
  select: [
    nameField,
    labelField,
    requiredField,
    disableField,
    tooltipField,
    {
      name: 'Default Value',
      key: 'value',
      type: 'select',
      hasToggle: true,
    },
    {
      name: 'Select Field type',
      key: 'selectFieldType',
      type: 'select',
      hasToggle: true,
      fieldVisibleDependentKey: 'Options',
      fieldVisibleDependentType: 'toggle',
    },
    {
      name: 'Select Parent Field',
      key: 'selectParentField',
      type: 'select',
      hasToggle: true,
    },
    {
      name: 'Options',
      key: 'options',
      type: 'inputList',
      error: errorType.invalidList,
      value: [
        {
          id: 'option1',
          name: 'Option1',
          label: 'Option 1',
          value: 'option1',
        },
      ],
    },
    layoutField,
    rowSpanField,
  ],
  radio: [
    nameField,
    labelField,
    requiredField,
    disableField,
    tooltipField,
    {
      name: 'Default Value',
      key: 'value',
      type: 'select',
      hasToggle: true,
    },
    {
      name: 'Align Options Horizontal',
      key: 'rowAlign',
      type: 'checkbox',
    },
    {
      name: 'Options',
      key: 'options',
      type: 'inputList',
      error: errorType.invalidList,
      value: [
        {
          id: 'option1',
          name: 'Option1',
          label: 'Option 1',
          value: 'option1',
        },
      ],
    },
    layoutField,
    rowSpanField,
  ],
  text: [
    {
      name: 'Style',
      key: 'size',
      type: 'select',
      value: TextOptions[0],
      defaultValue: TextOptions[0].value,
      options: TextOptions,
    },
    {
      name: 'Text',
      key: 'value',
      type: 'input',
    },
  ],
  datepicker: [
    nameField,
    labelField,
    requiredField,
    disableField,
    tooltipField,
    placeholderField,
    {
      name: 'Default Value',
      key: 'value',
      type: 'datepicker',
      errormsg: errorType.invalidDate,
      hasToggle: true,
      placeholder: 'set current date',
    },
    {
      name: 'Minimum Date',
      key: 'min',
      type: 'datepicker',
      errormsg: errorType.invalidDate,
      hasToggle: true,
    },
    {
      name: 'Maximum Date',
      key: 'max',
      type: 'datepicker',
      errormsg: errorType.invalidDate,
      hasToggle: true,
    },
    layoutField,
    rowSpanField,
  ],
  textarea: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: 'Default Value',
      key: 'value',
      type: 'input',
      hasToggle: true,
    },
    {
      name: 'Minimum Length',
      key: 'minLength',
      type: 'input',
      error: errorType.invalidMinLength,
      hasToggle: true,
      subType: 'number',
    },
    {
      name: 'Maximum Length',
      key: 'maxLength',
      type: 'input',
      error: errorType.invalidMaxLength,
      hasToggle: true,
      subType: 'number',
    },
    layoutField,
    rowSpanField,
  ],
  file: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    {
      name: 'Face Authentication',
      key: 'faceAuth',
      type: 'checkbox',
    },
    {
      name: 'File Type',
      key: 'fileTypes',
      type: 'multiSelect',
      options: FileOptions,
      hasToggle: true,
      fieldVisibleDependentKey: 'faceAuth',
      fieldVisibleDependentOptionValue: ['photo'],
      fieldVisibleDependentType: 'select',
    },
    {
      name: 'File Size',
      key: 'size',
      type: 'input',
      placeholder: 'Enter maximum file size in MB',
      hasToggle: true,
      subType: 'number',
    },
    layoutField,
    rowSpanField,
  ],
  image: [
    {
      name: 'Width(px)',
      key: 'previewWidth',
      type: 'input',
      subType: 'number',
    },
  ],
  photoCapture: [nameField, requiredField, layoutField, rowSpanField],
  button: [
    {
      name: 'Label',
      key: 'label',
      type: 'input',
      error: errorType.labelSize,
    },
  ],
  sectionStart: [
    nameField,
    labelField,
    {
      name: 'Border',
      key: 'border',
      type: 'checkbox',
    },
    {
      name: 'Max Instances',
      key: 'maxInstances',
      type: 'input',
      subType: 'number',
      hasToggle: true,
      placeholder: 'No Limit',
    },
  ],
};
