import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { get, round, some } from 'lodash';
import FileUpload from './FileUploader';
import FileUploadActions from '@apps/form/src/utils/FileUploadActions';
import { RootState } from '@apps/form/src/store';
import {
  useGetFileDetailsQuery,
  useUploadFileMutation,
} from '@apps/form/src/store/services/updateFormBuilder';
import { uploadFileToS3 } from '@apps/form/src/store/services/api';
import {
  updateFaceAuthDetails,
  updateFileUploadStatus,
  updateFormFileData,
} from '@apps/form/src/store/actions';
import {
  detectFace,
  onDetectionSuccess,
  readFileData,
  resizeImage,
} from '@apps/form/src/utils';
import { getItem } from '@apps/form/src/utils/store';
import {
  ERROR_MESSAGES,
  UPLOAD_STATUS,
  VALIDATION_ERROR_MESSAGES,
} from '@apps/form/src/utils/constant';
import { FileOptions } from '@apps/form/src/utils/BlockList/FileOptions';
import FieldLabel from '@apps/form/src/components/Tools/FieldLabel';
import {
  failFileUploadNotification,
  successFileUploadNotification,
} from '@apps/form/src/components/Notifications/ToastNotification';
import { FileDownloadFileData } from '@apps/form/src/containers/BlockNoteRenderer/types';
import {
  handleInputValidation,
  handleInvalidField,
} from '@apps/form/src/components/Tools/TriggerValidation/inputUtils';
import TriggerValidation from '@apps/form/src/components/Tools/TriggerValidation';
import FilePreview from '@apps/form/src/utils/FilePreview';

const File = (props: any) => {
  const {
    required,
    label,
    name,
    disabled,
    updatable,
    pre_filled,
    fileTypes,
    size,
    error,
    onChange,
    readOnly,
    tooltip,
    attrkey,
    value,
    faceAuth,
    id,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const { candidateDetails, faceAuthDetails, fileUploadStatus } =
    useSelector((state: RootState) => state?.form) || {};
  const { user_id } = getItem('user_details') || {};
  const inputRef = useRef<any>(null);
  const [fileSelected, setFileSelected] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [faceDetectedMessage, setFaceDetectedMessage] = React.useState('');
  const is_photo_type = some(fileTypes, (fileType) => fileType?.id === 'photo');
  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });
  const [isClicked, setIsClicked] = useState(false);

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    handleInvalidField(event, setValidation);
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleInputValidation(event, setValidation);
  };

  const uploadStatus = get(fileUploadStatus, attrkey ?? '');

  const [uploadFile] = useUploadFileMutation();
  const { data: file_download_file } = useGetFileDetailsQuery<{
    data: FileDownloadFileData | undefined;
  }>(
    {
      file_id: value,
    },
    {
      skip: typeof value !== 'number',
    },
  );

  const setUploadStatus = (status: any) =>
    attrkey && dispatch(updateFileUploadStatus({ [attrkey]: status }));

  const onFileUploadSuccess = (fileId: number) => {
    const file = get(inputRef.current, 'files.0');
    const dataURL = get(inputRef.current, 'dataURL');
    setUploadStatus(UPLOAD_STATUS.SUCCESS);
    onChange?.({ file, fileId });
    successFileUploadNotification();
    setValidation((prev) => ({ ...prev, triggerInvalid: false }));
    if (faceAuth && attrkey && candidateDetails?.id && dataURL) {
      dispatch(
        updateFaceAuthDetails({
          [attrkey]: {
            file: dataURL,
            file_name: file?.name,
            candidate_id: candidateDetails?.id,
          },
        }),
      );
    }
  };

  const onFileUploadFailure = () => {
    setUploadStatus(UPLOAD_STATUS.FAILED);
    inputRef.current.value = '';
    failFileUploadNotification();
  };

  const uploadCallback = (res: any) => {
    const { id, presigned_url } = get(res, 'data.file_upload_file', {});
    const file = get(inputRef.current, 'files.0');
    if (!presigned_url) {
      onFileUploadFailure();
    } else {
      uploadFileToS3(
        presigned_url,
        file,
        id,
        onFileUploadSuccess,
        onFileUploadFailure,
      );
    }
  };

  const onFileSelect = (e: any) => {
    const file = get(e, 'target.files.0');

    if (!file) return;
    const fileName = get(file, 'name');
    const fileType = fileName?.split('.')?.pop();

    const handleFileUpload = () => {
      setUploadStatus(UPLOAD_STATUS.UPLOADING);
      setFileSelected({
        fileName,
        fileType,
        fileSize: round(get(file, 'size', 0) / (1024 * 1024), 2) || 0,
      });
      uploadFile({ fileName, fileType, user_id, uploadCallback });
    };

    readFileData(file, (dataURL) => {
      inputRef.current.dataURL = dataURL;

      // faceDetection action
      if (is_photo_type)
        detectFace(
          `${dataURL}`,
          (detections: any) => {
            const { isFaceDetected, message } = onDetectionSuccess(detections);
            setFaceDetectedMessage(message);
            if (isFaceDetected) handleFileUpload();
          },
          () => setFaceDetectedMessage(ERROR_MESSAGES.UNKNOWN),
        );
      else handleFileUpload();

      // preview action
      if (is_photo_type) {
        const image = new Image();
        image.onload = function () {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          resizeImage(canvas, ctx, image);
          setPreviewImage(canvas.toDataURL('image/png'));
        };
        image.src = `${dataURL}`;
      }
    });
  };

  const onClearFile = (e: any) => {
    e.stopPropagation();
    setFileSelected(null);
    inputRef.current.value = '';
    dispatch(updateFormFileData({ [attrkey]: null }));
    setPreviewImage(null);
    setFaceDetectedMessage('');
  };

  useEffect(() => {
    inputRef.current?.setCustomValidity(
      required && (!fileSelected || uploadStatus !== UPLOAD_STATUS.SUCCESS)
        ? VALIDATION_ERROR_MESSAGES.REQUIRED
        : fileSelected?.fileSize > parseFloat(size)
          ? `Exceeds file size of ${size} MB`
          : '',
    );
    setValidation((prev) => ({
      ...prev,
      message: inputRef?.current?.validationMessage,
    }));
  }, [fileSelected, uploadStatus]);

  useEffect(() => {
    if (file_download_file) {
      const { id, original_filename, resource_url } = file_download_file || {};
      setFileSelected({
        id,
        fileName: original_filename,
        resource_url,
        fileType: original_filename?.split('.')?.pop(),
      });
      setUploadStatus(UPLOAD_STATUS.SUCCESS);
      if (is_photo_type) setPreviewImage(resource_url);
    }
  }, [file_download_file]);

  const onFileClickFn = () => {
    if (
      !disabled &&
      !readOnly &&
      !(uploadStatus === UPLOAD_STATUS.UPLOADING) &&
      !(!updatable && pre_filled)
    )
      inputRef.current.click();
  };

  const fileTypeList = is_photo_type
    ? 'JPG, JPEG, PNG'
    : (fileTypes?.length > 0 ? fileTypes : FileOptions)
        .map((type: any) => type.name)
        .join(', ');

  const authError = get(faceAuthDetails, `${attrkey}.error`);

  const handleClick = () => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 2000);
  };
  return (
    <div
      className={`gap-8 cursor-pointer bg-white hover:shadow-lg transition-shadow duration-200 w-fit pb-[27px] px-2 ${
        isClicked ? 'border-2 border-blue-600 rounded-lg' : ''
      }`}
      onClick={handleClick}
    >
      <div className="flex">
        {label && (
          <FieldLabel
            name={name}
            value={label}
            required={required}
            className={
              (disabled || (!updatable && pre_filled)) &&
              'group group-hover:cursor-pointer'
            }
            tooltip={tooltip}
          >
            {label}
          </FieldLabel>
        )}
        <input
          {...rest}
          ref={inputRef}
          type="file"
          accept={
            is_photo_type
              ? '.png , .jpeg , .jpg'
              : fileTypes?.map((type: any) => type.ext.join(',')).join(',')
          }
          name={name}
          onChange={onFileSelect}
          id={id}
          className="opacity-0 pointer-events-none w-0.5"
          aria-describedby={`${name}-error`}
          data-testid={rest['data-testid'] || 'input'}
          role="button"
          onInvalid={handleInvalid}
          onInput={handleInput}
        />
      </div>
      <FilePreview
        previewImage={previewImage}
        faceDetectedMessage={faceDetectedMessage}
        authError={authError}
      />

      <FileUpload
        fileSelected={fileSelected}
        onFileClickFn={onFileClickFn}
        onClearFile={onClearFile}
        getFileTrailingIcon={() => (
          <FileUploadActions
            uploadStatus={uploadStatus}
            onClearFile={onClearFile}
          />
        )}
        fileTypeList={fileTypeList}
        size={size}
        disabled={disabled}
        updatable={updatable}
        pre_filled={pre_filled}
        onFileUpload={onFileSelect}
      />
      {error && (
        <p
          className="font-normal text-xs text-rose-800 mt-2"
          id={`${name}-description`}
          data-testid="input_error_msg"
        >
          {error}
        </p>
      )}
      <TriggerValidation
        validationTrigger={validation?.triggerInvalid}
        validationMessage={validation?.message}
        name={name}
        alterClassName="mt-2"
      />
    </div>
  );
};

File.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  size: PropTypes.number,
  fileTypes: PropTypes.array,
  updatable: PropTypes.bool,
};

export default memo(File);
