import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';
import { DateTime } from 'luxon';
import { RootState } from '@apps/form/src/store';
import { uploadFileToS3 } from '@apps/form/src/store/services/api';
import { useUploadFileMutation } from '@apps/form/src/store/services/updateFormBuilder';
import {
  updateFileUploadStatus,
  updateFormFileData,
} from '@apps/form/src/store/actions';
import { detectFace, onDetectionSuccess } from '@apps/form/src/utils';
import { getItem } from '@apps/form/src/utils/store';
import {
  ERROR_MESSAGES,
  FACE_DETECTION_MESSAGES,
  ROUTE_TYPE,
  UPLOAD_STATUS,
  VALIDATION_ERROR_MESSAGES,
} from '@apps/form/src/utils/constant';
import picture_placeholder from '@apps/form/src/images/picture_placeholder.png';
import warning from '@apps/form/src/images/warning.png';
import Button from '@apps/form/src/components/NewButton';
import { handleInvalidField } from '@apps/form/src/components/Tools/TriggerValidation/inputUtils';
import TriggerValidation from '@apps/form/src/components/Tools/TriggerValidation';

type CaptureState = 'start' | 'capture' | 'recapture';

type ButtonMapType = Record<
  CaptureState,
  { label: string; onClickFn: () => void }
>;

const PhotoCapture = ({
  required,
  attrkey,
  id,
}: {
  required?: boolean;
  attrkey?: string;
  id?: string;
}) => {
  const dispatch = useDispatch();
  const { user_id } = getItem('user_details') || {};
  const { formState, candidateDetails, fileUploadStatus } =
    useSelector((state: RootState) => state?.form) || {};
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [captureState, setCaptureState] = React.useState<CaptureState>('start');
  const [faceDetectedMessage, setFaceDetectedMessage] = React.useState('');
  const inputRef = useRef<any>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    handleInvalidField(event, setValidation);
  };

  const [uploadFile] = useUploadFileMutation();

  const uploadStatus = get(fileUploadStatus, attrkey ?? '');

  const setUploadStatus = (status: any) =>
    attrkey && dispatch(updateFileUploadStatus({ [attrkey]: status }));

  const onFileUploadSuccess = (fileId: number) => {
    setUploadStatus(UPLOAD_STATUS.SUCCESS);
    id && dispatch(updateFormFileData({ [id]: fileId }));
    setValidation((prev) => ({ ...prev, triggerInvalid: false }));
  };

  const onFileUploadFailure = () => {
    setUploadStatus(UPLOAD_STATUS.FAILED);
    setCaptureState('recapture');
    inputRef.current.file = null;
  };

  const uploadCallback = (res: any) => {
    const { id, presigned_url } = get(res, 'data.file_upload_file', {});
    const file = get(inputRef.current, 'file.dataURL');

    if (!presigned_url || !file) onFileUploadFailure();
    else
      uploadFileToS3(
        presigned_url,
        file,
        id,
        onFileUploadSuccess,
        onFileUploadFailure,
      );
  };

  function stopCameraStream() {
    const stream = videoRef.current?.srcObject as MediaStream;
    const tracks = stream?.getTracks();
    tracks.forEach((track) => track?.stop());
    if (videoRef.current) videoRef.current.srcObject = null;
  }

  const onCapture = useCallback(() => {
    try {
      // create dataURL
      const canvas = canvasRef.current;
      const video = videoRef.current;
      if (!canvas || !video) return;
      const { clientWidth: parentWidth, clientHeight: parentHeight } =
        canvas.parentElement || { clientWidth: 288, clientHeight: 240 };

      const videoAspectRatio = video?.videoWidth / video?.videoHeight;
      const containerAspectRatio = parentWidth / parentHeight;

      let targetWidth, targetHeight;

      if (containerAspectRatio > videoAspectRatio) {
        // Container is wider than video aspect ratio, fix height and adjust width
        targetHeight = parentHeight;
        targetWidth = targetHeight * videoAspectRatio;
      } else {
        // Container is taller, fix width and adjust height
        targetWidth = parentWidth;
        targetHeight = targetWidth / videoAspectRatio;
      }

      // Adjust canvas size
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      canvas.style.width = `${targetWidth}px`;
      canvas.style.height = `${targetHeight}px`;

      const context = canvas?.getContext('2d');
      context?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL('image/png');
      setCaptureState('recapture');
      stopCameraStream();

      // file upload
      const fileName = `${candidateDetails?.id}_${DateTime.local().toFormat('yyyy-MM-dd')}.png`;
      inputRef.current.file = { dataURL, name: fileName };
      detectFace(
        dataURL,
        (detections: any) => {
          const { isFaceDetected, message } = onDetectionSuccess(detections);
          setFaceDetectedMessage(message);
          setCaptureState('recapture');
          if (isFaceDetected) {
            setUploadStatus(UPLOAD_STATUS.UPLOADING);
            uploadFile({
              fileName,
              fileType: 'png',
              user_id,
              file_type_id: 28,
              uploadCallback,
            });
          }
        },
        () => setFaceDetectedMessage(ERROR_MESSAGES.UNKNOWN),
      );
    } catch (e) {
      console.log('Error capturing image:', e);
    }
  }, [candidateDetails]);

  const onStartCamera = useCallback(() => {
    id && dispatch(updateFormFileData({ [id]: null }));

    const videoEl = videoRef.current;
    if (!videoEl) return;
    setIsLoading(true);
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoEl.srcObject = stream;
        videoEl.play();
        setIsLoading(false);
        setCaptureState('capture');
        inputRef.current.file = null;
        setFaceDetectedMessage('');
      })
      .catch((err) => {
        if (
          err.name === 'NotAllowedError' ||
          err.name === 'PermissionDeniedError'
        )
          setError('permission');
        else if (
          err.name === 'NotFoundError' ||
          err.name === 'DevicesNotFoundError'
        )
          setError('notfound');
        else setError('unknown');
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (required && !inputRef.current?.file)
      inputRef.current?.setCustomValidity(VALIDATION_ERROR_MESSAGES.REQUIRED);
    else inputRef.current?.setCustomValidity('');
    setValidation((prev) => ({
      ...prev,
      message: inputRef?.current?.validationMessage,
    }));
  }, [uploadStatus, required, captureState]);

  const buttonMap: ButtonMapType = {
    start: { label: 'Start Camera', onClickFn: onStartCamera },
    capture: { label: 'Capture Photo', onClickFn: onCapture },
    recapture: { label: 'Recapture Photo', onClickFn: onStartCamera },
  };

  const uploadFailed = uploadStatus === UPLOAD_STATUS.FAILED;

  return (
    <div className="flex flex-col w-full">
      <div className="flex justify-center items">
        <div className="flex flex-col items-start">
          <div className="flex flex-col justify-center items-center rounded-md p-2 bg-slate-100 w-full">
            {captureState === 'start' &&
              (!error ? (
                <img
                  src={picture_placeholder}
                  alt="img"
                  className="w-72 h-60"
                />
              ) : (
                <div className="flex flex-col justify-center items-center w-72 h-60 gap-2 text-center">
                  <img src={warning} alt="warning icon" className="w-10" />
                  {error !== 'permission' ? (
                    <>
                      <div>No functional camera detected on your system.</div>
                      <div>You need a camera to move forward.</div>
                    </>
                  ) : (
                    <>
                      <div>You need to capture your photo.</div>
                      <div>
                        Provide browser permissions to enable the camera.
                      </div>
                      <a
                        target="_blank"
                        href="https://talview.freshdesk.com/support/solutions/articles/11000121624-configuring-browser-permissions-on-windows"
                        rel="noreferrer"
                        className="text-blue-400"
                      >
                        Need Help?
                      </a>
                    </>
                  )}
                </div>
              ))}
            <div
              className={classNames(
                'w-72 h-60 flex justify-center',
                captureState === 'start' && 'hidden',
              )}
            >
              <video
                ref={videoRef}
                className={classNames(
                  captureState !== 'capture' && 'hidden',
                  'w-full h-full',
                )}
                playsInline
              />
              <canvas
                ref={canvasRef}
                className={classNames(
                  captureState !== 'recapture' && 'hidden',
                  'w-full h-full',
                )}
                data-testid="canvas-element"
              ></canvas>
            </div>
            {formState === ROUTE_TYPE.RENDERER && (
              <div className="flex">
                <Button
                  onClick={get(buttonMap, `${captureState}.onClickFn`)}
                  loading={
                    isLoading || uploadStatus === UPLOAD_STATUS.UPLOADING
                  }
                  className="mt-2"
                >
                  {get(buttonMap, `${captureState}.label`)}
                </Button>
                <input
                  ref={inputRef}
                  className="opacity-0 w-0.5 pointer-events-none"
                  onInvalid={handleInvalid}
                />
              </div>
            )}
            {(uploadFailed || faceDetectedMessage) && (
              <div
                className={classNames(
                  'mt-2 text-sm text-center',
                  faceDetectedMessage ===
                    FACE_DETECTION_MESSAGES.FACE_DETECTED && !uploadFailed
                    ? 'text-green-500'
                    : 'text-red-500',
                )}
              >
                {uploadFailed ? ERROR_MESSAGES.UNKNOWN : faceDetectedMessage}
              </div>
            )}
          </div>
          <TriggerValidation
            validationTrigger={validation?.triggerInvalid}
            validationMessage={validation?.message}
            alterClassName="mt-2" // Stacked below and aligned left
          />
        </div>
      </div>
    </div>
  );
};

export default PhotoCapture;
