export const TextOptions: {
  label: string;
  value: 'text-3xl' | 'text-2xl' | 'text-xl' | 'text-base';
}[] = [
  { value: 'text-base', label: 'Paragraph' },
  { value: 'text-3xl', label: 'Heading 1' },
  { value: 'text-2xl', label: 'Heading 2' },
  { value: 'text-xl', label: 'Heading 3' },
];

export const valueFields = ['minLength', 'maxLength', 'value', 'min', 'max'];
export const valueNames = [
  'Minimum Length',
  'Maximum Length',
  'Default Value',
  'Minimum Value',
  'Maximum Value',
];

export const EMAILREGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export enum errorType {
  'invalidMinLength' = 'Invalid minimum length',
  'invalidMaxLength' = 'Invalid maximum length',
  'invalidMinValue' = 'Invalid minimum value',
  'invalidMaxValue' = 'Invalid maximum value',
  'emptyName' = 'Name cannot be empty',
  'minGreaterThanMax' = 'Minimum value should be less than the maximum value',
  'defaultLongerThanMax' = 'Default value should be shorter than the maximum length',
  'defaultShorterThanMin' = 'Default value should be longer than the minimum length',
  'defaultGreaterThanMax' = 'Default value should be less than the maximum value',
  'defaultLessThanMin' = 'Default value should be greater than the minimum value',
  'invalidNumber' = 'Invalid number',
  'invalidEmail' = 'Invalid email',
  'invalidList' = 'Duplicate values or labels',
  'invalidDate' = 'Invalid Date',
  'labelSize' = 'Label should be less than 20 characters',
}

export const layoutOptions = [
  {
    label: '1 column',
    value: 1,
  },
  {
    label: '2 columns',
    value: 2,
  },
  {
    label: '3 columns',
    value: 3,
  },
];
