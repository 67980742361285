import React, {
  memo,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { find } from 'lodash';
import classNames from 'classnames';
import { PropertyBoxType } from '@apps/form/src/components/Block/PropertyBox';
import { resetFieldsEffect } from '@apps/form/src/components/SidePanel/effects';
import { getInputComponent } from '@apps/form/src/components/SidePanel/inputComponents';
import { getFieldProps } from '@apps/form/src/components/SidePanel/utils';
import { onInputComponentChange } from '@apps/form/src/components/SidePanel/blockValidators';
import { Field } from '@apps/form/src/components/SidePanel';

export interface MenuBoxProps {
  propertyBox: Field[];
  setProperties?: (obj: object) => void;
  validations: object;
  block?: { type: string; properties: object };
  properties?: object;
  menuFieldsRef?: any;
  selectFieldTypes?: any[];
  selectParentFields?: any[];
}

const MenuBox: React.FC<MenuBoxProps> = ({
  propertyBox,
  validations,
  block,
  menuFieldsRef,
  selectFieldTypes,
  selectParentFields,
}) => {
  const [errorList, setErrorList] = useState<any>({});
  const [fields, setFields] = useState<Field[]>(
    menuFieldsRef?.current || propertyBox,
  );
  const [propertyBoxState, setPropertyBoxState] = useState<any>({
    ...fields,
    validations,
  });

  const hideCustomOptions = useMemo(() => {
    try {
      const boxStateProperties =
        find(propertyBoxState, { key: 'fileTypes' }) ||
        find(propertyBoxState, { key: 'selectFieldType' }) ||
        {};

      if (boxStateProperties?.fieldVisibleDependentKey) {
        if (boxStateProperties?.fieldVisibleDependentType === 'select') {
          const hasOption = boxStateProperties?.value?.some(
            (optionValue: any) =>
              boxStateProperties?.fieldVisibleDependentOptionValue?.includes(
                optionValue?.value,
              ),
          );

          return (
            boxStateProperties?.fieldVisibleDependentType === 'select' &&
            !hasOption
          );
        } else if (boxStateProperties?.fieldVisibleDependentType === 'toggle') {
          return (
            boxStateProperties?.fieldVisibleDependentType === 'toggle' &&
            boxStateProperties?.value &&
            boxStateProperties?.isEnabled
          );
        } else {
          console.error('fieldVisibleDependentType not found');
        }
      } else {
        console.error('fieldVisibleDependentKey not found');
      }
    } catch (error) {
      console.error(error);
    }
  }, [propertyBoxState]);

  useLayoutEffect(() => {
    if (!menuFieldsRef?.current)
      resetFieldsEffect(block, setFields, setPropertyBoxState, menuFieldsRef);
  }, []);

  useEffect(() => {
    if (menuFieldsRef) menuFieldsRef.current = fields;
  }, [fields]);

  return (
    <div className="flex flex-col gap-2 p-2 bg-white" data-testid="menubox">
      {fields?.map((field: PropertyBoxType, index: number) => (
        <div
          key={'box.type' + index}
          data-testid="menubox-element"
          className={classNames({
            hidden:
              hideCustomOptions &&
              (field?.name === 'Face Authentication' ||
                field?.name === 'Options'),
          })}
        >
          {React.cloneElement(
            getInputComponent(
              field,
              propertyBoxState,
              setPropertyBoxState,
              errorList,
              setErrorList,
            ),
            {
              ...getFieldProps(field, propertyBoxState),
              onChange: (value: string) =>
                onInputComponentChange(
                  field,
                  value,
                  propertyBoxState,
                  errorList,
                  setErrorList,
                  setPropertyBoxState,
                ),
              fieldEnableChange: (value: boolean) => {
                const newPropertyBoxState = { ...propertyBoxState };
                Object.values(newPropertyBoxState).map((box: any) => {
                  const newBox = box;
                  if (box && box?.key === field?.key) {
                    newBox.isEnabled = value;
                  }
                  return newBox;
                });
                setPropertyBoxState(newPropertyBoxState);
              },
              ...(field?.key === 'selectFieldType' && {
                options: selectFieldTypes || [],
              }),
              ...(field?.key === 'selectParentField' && {
                options: selectParentFields || [],
              }),
            },
          )}
        </div>
      ))}
    </div>
  );
};
export default memo(MenuBox);
