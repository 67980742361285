import React from 'react';
import classNames from 'classnames';
import { FACE_DETECTION_MESSAGES } from '@apps/form/src/utils/constant';
import { FilePreviewProps } from './types';

const FilePreview: React.FC<FilePreviewProps> = ({
  previewImage,
  faceDetectedMessage,
  authError,
}) => {
  const hasMessage = authError || faceDetectedMessage;
  if (!previewImage && !hasMessage) return null;

  const messageText = authError ?? faceDetectedMessage;
  const messageClass = classNames(
    'mb-1 text-sm',
    faceDetectedMessage === FACE_DETECTION_MESSAGES.FACE_DETECTED && !authError
      ? 'text-green-500'
      : 'text-red-500',
  );

  return (
    <>
      {previewImage && (
        <img src={previewImage} alt="preview" className="max-h-48 mb-1" />
      )}
      {hasMessage && <div className={messageClass}>{messageText}</div>}
    </>
  );
};

export default FilePreview;
