import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { useParams, useSearchParams } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithPayload, Dispatch } from '@reduxjs/toolkit';
import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { RootState, store } from '@apps/form/src/store';
import * as faceapi from 'face-api.js';
import * as sentry from '@sentry/react';
import { cloneDeep, get, includes, isEmpty, map, some } from 'lodash';
import classNames from 'classnames';
// import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareMinus,
  faSquarePlus,
} from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useCreateBlockNote } from '@blocknote/react';
import '@blocknote/core/fonts/inter.css';
import {
  useCreateAuthRequestMutation,
  useFetchCandidateDetailsQuery,
  useGetFormInstanceQuery,
  useGetFormStructureQuery,
  useSaveFormInstanceMutation,
} from '@apps/form/src/store/services/updateFormBuilder';
import {
  updateCandidateDetails,
  updateFaceAuthDetails,
  updateFormData,
  updateFormFileData,
  updateFormState,
} from '@apps/form/src/store/actions';
import { Data } from '@apps/form/src/Pages/Editor';
import { getItem, setItem } from '@apps/form/src/utils/store';
import { trackEvent } from '@apps/form/src/utils/mixpanel/mixpanelActions';
import { mixpanelEvents } from '@apps/form/src/utils/mixpanel/mixpanelEvent';
import {
  DEVICE_TYPE,
  ERROR_MESSAGES,
  FORM_TYPE,
  ROUTE_TYPE,
  UPLOAD_STATUS,
} from '@apps/form/src/utils/constant';
import {
  getFormattedSaveData,
  initForm,
  initobj,
  postFormData,
} from '@apps/form/src/containers/BlockNoteRenderer/utils/formDataHelpers';
import {
  handleSectionInstances,
  initBlocksRender,
  renderContent,
} from '@apps/form/src/containers/BlockNoteRenderer/utils/renderHelpers';
import SectionGroupRender from '@apps/form/src/containers/BlockNoteRenderer/SectionGroupRender';
import {
  CandidateData,
  DeviceType,
  FieldData,
  FormRendererProps,
  FormTenant,
  FormTenantName,
  LogicProps,
} from '@apps/form/src/containers/BlockNoteRenderer/types';
import { prefillSupportedFields } from '@apps/form/src/containers/BlockNoteRenderer/constants';
import Loader from '@apps/form/src/components/Loader';
import Toggle from '@apps/form/src/components/Popover/MenuPopover/ToggleSwitch';
import { schema } from '@apps/form/src/components/BlockNoteEditor';
import Button from '@apps/form/src/components/NewButton';
import {
  failSaveFormInstanceNotification,
  successSaveFormInstanceNotification,
} from '@apps/form/src/components/Notifications/ToastNotification';
import { customBlocksTypes } from '@apps/form/src/components/Tools/CustomBlocks/utils';

async function loadModels() {
  try {
    await faceapi.nets.tinyFaceDetector.loadFromUri('/weights');
    await faceapi.nets.faceLandmark68Net.loadFromUri('/weights');
    await faceapi.nets.faceRecognitionNet.loadFromUri('/weights');
  } catch (error) {
    console.error('Error loading models: ', error);
  }
}

export const processFaceAuthDetails = async (
  faceAuthDetails: FieldData,
  dispatch: Dispatch,
  createAuthRequest: MutationTrigger<any>,
  updateFaceAuthDetails: ActionCreatorWithPayload<Partial<FieldData>>,
) => {
  if (isEmpty(faceAuthDetails)) return false;
  let hasError = false;
  await Promise.all(
    map(faceAuthDetails, async (details, key) => {
      try {
        const response = await createAuthRequest(details).unwrap();
        const error_message = get(
          response,
          'ca_create_auth_request.error_message',
        );

        if (error_message) {
          hasError = true;
          dispatch(
            updateFaceAuthDetails({
              [key]: {
                ...details,
                error: error_message,
              },
            }),
          );
        }
      } catch {
        hasError = true;
        dispatch(
          updateFaceAuthDetails({
            [key]: { ...details, error: ERROR_MESSAGES.FACE_AUTH },
          }),
        );
      }
    }),
  );
  return hasError;
};

export const loadSavedContent = (
  content: Array<Record<string, any>>,
  form_instance_data: FieldData,
  candidateDetails: FieldData,
) => {
  try {
    const formData: FieldData = {};
    const formFileData: FieldData = {};
    const savedContent = map(cloneDeep(content), (block) => {
      const attrKey = block?.props?.attrkey;
      const blockId = block?.id;
      const type = block?.type;
      if (attrKey && includes(prefillSupportedFields, type)) {
        const value =
          form_instance_data?.[blockId] || candidateDetails?.[attrKey];
        if (value) {
          block.props.value = value;
          block.props.pre_filled = true;
          (type === customBlocksTypes.file ? formFileData : formData)[blockId] =
            value;
        }
      }
      return block;
    });
    return { savedContent, formData, formFileData };
  } catch (error) {
    console.log('load saved content error', error);
    return { savedContent: content };
  }
};

export const renderIcon = (
  icon: IconProp,
  isDisabled: boolean,
  onClickFn: () => void,
) => (
  <FontAwesomeIcon
    icon={icon}
    onClick={() => !isDisabled && onClickFn?.()}
    className={classNames(
      'mx-2',
      isDisabled
        ? 'cursor-not-allowed text-gray-500'
        : 'cursor-pointer text-blue-600',
    )}
    size="2xl"
  />
);

export const handleFormDetails = async (
  response: FormTenant,
): Promise<FormTenantName | null> => {
  try {
    if (!response?.tenant?.name || !response?.form_name) {
      throw new Error('Missing required form details');
    }

    const formTenantName: FormTenantName = {
      tenant_name: response?.tenant?.name,
      form_name: response?.form_name,
    };

    await setItem('form_tenant_name', formTenantName);

    return formTenantName;
  } catch (error) {
    sentry.captureException(error);
    console.error('Error processing form details:', error);
    return null;
  }
};

const BlockNoteRenderer: React.FC<FormRendererProps> = ({
  setFormState,
  formType,
  formContent,
}) => {
  const { id } = useParams() || {};
  const [searchParams] = useSearchParams();
  const model = searchParams.get('model') || 'sch_model';
  const model_id = searchParams.get('model_id') || 0;
  const dispatch = useDispatch();
  const { formData, formFileData, faceAuthDetails, fileUploadStatus } =
    useSelector((state: RootState) => state?.form) || {};
  const editor = useCreateBlockNote({ schema });

  const [deviceType, setDeviceType] = useState<DeviceType>(DEVICE_TYPE.DESKTOP);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [groupedContent, setGroupedContent] = useState<any>([]);
  const instanceSectionsRef = useRef<FieldData>({});
  const rootRef = useRef<FieldData>({});
  const logicsRef = useRef<LogicProps[]>([]);
  const { default_role } = getItem('user_details') || {};

  const [saveForm] = useSaveFormInstanceMutation();
  const { data, status } = useGetFormStructureQuery(
    {
      id,
      state: ROUTE_TYPE.RENDERER,
    },
    { skip: !!formType },
  );

  useEffect(() => {
    if (data) {
      handleFormDetails(data).then((formDetails) => {
        if (formDetails) {
          trackEvent(mixpanelEvents.FORM_LANDED);
        }
      });
    }
  }, [data]);

  const { type, structure, form_version_id } = (data || {}) as Data;
  const { data: candidateData } = useFetchCandidateDetailsQuery(null, {
    skip: type !== FORM_TYPE.REGISTRATION || default_role !== 'CANDIDATE',
  });

  const candidateDetails: CandidateData = useMemo(
    () => get(candidateData, 'can_candidate.0', {}),
    [candidateData],
  );

  const { data: form_instance_data } = useGetFormInstanceQuery<{
    data: FieldData;
  }>(
    {
      form_version_id,
    },
    {
      skip: !form_version_id,
    },
  );

  const formTypeValue = type || formType;
  const content = structure?.doc || formContent?.doc;
  const btnProps = structure?.button?.props ||
    formContent?.button?.props || { label: '' };

  const { user_id } = getItem('user_details') || {};
  const savedKey = `frm_save_${user_id}_${id}`;

  const setFormData = (fieldValues: FieldData) =>
    dispatch(updateFormData({ savedKey, fieldValues }));

  const setFormFileData = (fieldValues: FieldData) =>
    dispatch(updateFormFileData(fieldValues));

  useEffect(() => {
    dispatch(updateFormState(ROUTE_TYPE.RENDERER));
    initForm(searchParams, initobj);
    const eventMethod = 'addEventListener';
    const eventer = window[eventMethod];
    const messageEvent = 'message';

    eventer(messageEvent, (event) => {
      //Trigger formSubmit from parent (For Recruit)
      if (event.data.type === 'submitEvaluationForm') {
        document.getElementById('submitButton')?.click();
      }
    });
    loadModels();
  }, []);

  useEffect(() => {
    if (content) {
      const candidateDetails = get(candidateData, 'can_candidate.0', {});
      const {
        savedContent,
        formData = {},
        formFileData = {},
      } = loadSavedContent(content, form_instance_data, candidateDetails);
      setFormData(formData);
      setFormFileData(formFileData);
      (async () => {
        const { groupedBlocks, instanceSections, conditionalLogics } =
          await initBlocksRender(
            savedContent,
            setFormFileData,
            setFormData,
            editor,
            logicsRef,
          );
        setGroupedContent(groupedBlocks);
        instanceSectionsRef.current = instanceSections;
        logicsRef.current = conditionalLogics;
      })();
      if (candidateDetails) dispatch(updateCandidateDetails(candidateDetails));
    }
  }, [content, candidateDetails, form_instance_data]);

  const [createAuthRequest] = useCreateAuthRequestMutation();

  const onFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    formTypeValue: FormRendererProps['formType'],
  ) => {
    try {
      event?.preventDefault();
      setIsSubmitting(true);

      const hasError = await processFaceAuthDetails(
        faceAuthDetails,
        dispatch,
        createAuthRequest,
        updateFaceAuthDetails,
      );

      if (hasError) return;

      const saveFormattedData = getFormattedSaveData(formData, formFileData);
      const saveFormObj: {
        formFieldsData: any;
        formId: number;
        model?: string;
        model_id?: number;
      } = {
        formFieldsData: saveFormattedData,
        formId: Number(id),
      };
      saveFormObj['model'] = model;
      saveFormObj['model_id'] = Number(model_id);
      await saveForm({
        ...saveFormObj,
        saveFormSuccessCallback: (formInstanceId: number) => {
          postFormData(formData, formInstanceId, formTypeValue);
          successSaveFormInstanceNotification();
        },
        saveFormFailureCallback: () => {
          failSaveFormInstanceNotification();
        },
      });
      trackEvent(mixpanelEvents.FORM_SUBMIT);
    } catch (e) {
      sentry.captureException(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const grids = useMemo(
    () =>
      map(groupedContent, ({ htmlBlocks, layout, props }, index) => {
        const { maxInstances } = instanceSectionsRef.current[index] || {};

        const handleInstanceUpdate = (action: 'add' | 'subtract') => {
          const {
            targetItem,
            repeatCount,
            sectionLogics = [],
          } = handleSectionInstances(
            index,
            action,
            groupedContent,
            instanceSectionsRef,
            setFormFileData,
            setFormData,
            logicsRef,
            savedKey,
          ) || {};

          if (props?.attrkey && targetItem && typeof repeatCount === 'number') {
            const instanceKey = `${props.attrkey}_instance`;
            const rootElement = document.getElementById(instanceKey);

            if (rootElement) {
              // Initialize root if not already created
              let root = rootRef.current[instanceKey];
              if (!root) {
                root = ReactDOM.createRoot(rootElement);
                rootRef.current[instanceKey] = root;
              }

              // Determine button disabled states
              const isAddDisabled = maxInstances && repeatCount >= maxInstances;
              const isSubtractDisabled = repeatCount === 0;

              // Update instanceSectionsRef
              instanceSectionsRef.current[index] = {
                ...instanceSectionsRef.current[index],
                instances: repeatCount,
              };

              // Render SectionGroupRender component
              root.render(
                <Provider store={store}>
                  <SectionGroupRender
                    sectionContent={targetItem}
                    deviceType={deviceType}
                    sectionLogics={sectionLogics}
                    isAddDisabled={isAddDisabled}
                    isSubtractDisabled={isSubtractDisabled}
                    savedKey={savedKey}
                    handleInstanceUpdate={handleInstanceUpdate}
                  />
                </Provider>,
              );

              return { repeatCount, htmlBlocks };
            }
          }
        };

        return (
          <div
            className={classNames(
              props?.border && 'border border-solid mb-4 p-4 rounded-md',
              props?.hide && 'hidden',
            )}
            id={`${props?.attrkey}_instance_conditional_logic`}
          >
            <div id={`${props?.attrkey}_instance`}>
              {renderContent(htmlBlocks, layout, deviceType)}
              {get(props, 'toggleState.maxInstances') && (
                <>
                  {renderIcon(
                    faSquarePlus,
                    typeof maxInstances === 'number' && maxInstances <= 0,
                    () => handleInstanceUpdate('add'),
                  )}
                  {renderIcon(faSquareMinus, true, () =>
                    handleInstanceUpdate('subtract'),
                  )}
                </>
              )}
            </div>
          </div>
        );
      }),
    [groupedContent, deviceType],
  );

  const isButtonDisabled = useMemo(
    () => some(fileUploadStatus, (val) => val === UPLOAD_STATUS.UPLOADING),
    [fileUploadStatus],
  );

  return (
    <div className="max-h-screen pl-4 pr-4 w-full min-h-screen bg-white block-render">
      {formType && (
        <div className="cursor-pointer w-full flex justify-end p-5 bg-white">
          <div className="mr-3">
            <Toggle
              labelClassNames="text-gray-500"
              label={'Mobile View'}
              onChange={(checked: boolean) => {
                if (checked) setDeviceType(DEVICE_TYPE.MOBILE);
                else setDeviceType(DEVICE_TYPE.DESKTOP);
              }}
              size="sm"
              checked={deviceType === DEVICE_TYPE.MOBILE}
            />
          </div>
          <div
            role="button"
            data-testid="back-to-editor"
            onClick={() => setFormState?.(ROUTE_TYPE.BUILDER)}
            className="text-sm font-light	text-gray-500	"
            title="Back to Editor"
          >
            {/* <FontAwesomeIcon icon={solid('right-from-line')} /> */}
            Back to Editor
          </div>
        </div>
      )}
      <div
        className={classNames(
          'mx-auto mt-2 px-1 py-5 sm:px-5 sm:py-10 bg-white overflow-auto no-scrollbar',
          formType
            ? 'h-[85vh] max-h-[85vh] ' +
                (deviceType === DEVICE_TYPE.MOBILE
                  ? 'max-w-sm shadow-lg'
                  : 'max-w-6xl')
            : 'max-h-screen h-screen',
        )}
      >
        <form
          onSubmit={(event) => onFormSubmit(event, formTypeValue)}
          id={
            formTypeValue === FORM_TYPE.REGISTRATION
              ? 'assessmentForm'
              : 'evaluationForm'
          }
          className="flex flex-col h-full"
        >
          <div className="flex-1 overflow-auto no-scrollbar relative">
            {status === 'pending' ? (
              <div className="h-full flex items-center justify-center grow">
                <Loader />
              </div>
            ) : (
              <div className="bn-container">{grids}</div>
            )}
          </div>
          <div className="w-100 flex justify-center mt-4 flex-shrink-0">
            <Button
              id="submitButton"
              buttonType="submit"
              className={classNames(
                formTypeValue !== FORM_TYPE.REGISTRATION && 'hidden',
              )}
              loading={isSubmitting}
              disabled={isButtonDisabled}
              tooltip={
                isButtonDisabled && {
                  message: 'file upload is in progress.',
                  id: 'form-submit',
                }
              }
            >
              {btnProps?.label || 'Submit'}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(BlockNoteRenderer);
