import mixpanel from 'mixpanel-browser';
import { get } from 'lodash';
import { getItem } from '@apps/form/src/utils/store';

const getFormIdFromUrl = () => {
  const path = window.location.pathname;
  const match = path?.match(/\/renderer\/(\d+)/);
  return match ? match[1] : null;
};

export const trackEvent = (event: string, data?: any) => {
  const { user_id, tenant_id } = getItem('user_details') || {};
  const { tenant_name, form_name } = getItem('form_tenant_name') || {};
  const form_id = getFormIdFromUrl();
  const defaultData = {
    Application: 'Form App',
    'Form ID': form_id,
    'Form Name': form_name,
    'User ID': user_id,
    'Tenant ID': tenant_id,
    'Tenant Name': tenant_name,
    ...(data || {}),
  };
  if (get(mixpanel, '__loaded')) {
    mixpanel.track(event, { ...defaultData });
  }
};
