/**
 *
 * Checkbox
 *
 */

import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import classNames from 'classnames';
import MarkdownWrapper from '@apps/form/src/components/MarkdownWrapper';
import { handleInvalidField } from '@apps/form/src/components/Tools/TriggerValidation/inputUtils';
import { VALIDATION_ERROR_MESSAGES } from '@apps/form/src/utils/constant';
import TriggerValidation from '@apps/form/src/components/Tools/TriggerValidation';

const sizeMap = {
  xs: 'h-3 w-3',
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-5 w-5',
};
function CheckBox(props: any) {
  const {
    id,
    onChange,
    size,
    label,
    disabled,
    updatable,
    option,
    checked,
    required,
    inputRef: externalRef,
    pre_filled,
  } = props;

  const internalRef = useRef<HTMLInputElement>(null);
  const inputRef = externalRef || internalRef;
  const [isChecked, setIsChecked] = useState(checked);

  const [validation, setValidation] = useState({
    triggerInvalid: false,
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = e.target.checked;
    setIsChecked(newChecked);
    onChange(newChecked);
  };

  const handleInvalid = (event: React.InvalidEvent<HTMLInputElement>) => {
    handleInvalidField(event, setValidation);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    if (inputRef?.current) {
      if (required && !isChecked) {
        inputRef.current.setCustomValidity(VALIDATION_ERROR_MESSAGES.REQUIRED);
      } else {
        inputRef.current.setCustomValidity('');
      }
      setValidation((prev) => ({
        ...prev,
        message: inputRef.current.validationMessage,
      }));
    }
  }, [isChecked, required, inputRef]);

  return (
    <>
      <div className="relative w-full px-[0.5rem] py-[0.38rem] flex align-middle items-center">
        <input
          data-testid={`checkbox-input-${id}`}
          id={`checkbox-${id}`}
          type="checkbox"
          onChange={handleChange}
          className={classNames(
            get(sizeMap, size, 'h3 w-3'),
            disabled || (!updatable && (option?.pre_filled || pre_filled))
              ? 'text-zinc-300 border-zinc-300'
              : 'text-blue-600 focus:ring-blue-500 border-gray-500 ',
            'rounded-sm',
          )}
          disabled={
            disabled || (!updatable && (option?.pre_filled || pre_filled))
          }
          checked={isChecked}
          value={checked}
        />
        {required && (
          <input
            ref={inputRef}
            className="absolute opacity-0 pointer-events-none"
            role="button"
            onInvalid={handleInvalid}
          />
        )}
        {label && (
          <label
            data-testid="checkbox-label"
            htmlFor={`checkbox-${id}`}
            className={classNames(
              'ml-2 text-sm cursor-pointer',
              disabled || (!updatable && (option?.pre_filled || pre_filled))
                ? 'text-zinc-300 '
                : 'text-stone-900',
            )}
          >
            <MarkdownWrapper styles={required && 'required-label'} prose>
              {label}
            </MarkdownWrapper>
          </label>
        )}
      </div>
      <TriggerValidation
        validationTrigger={validation?.triggerInvalid}
        validationMessage={validation?.message}
      />
    </>
  );
}

CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xs']),
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  updatable: PropTypes.bool,
  option: PropTypes.object,
  value: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  required: PropTypes.bool,
  inputRef: PropTypes.object,
  pre_filled: PropTypes.bool,
};

CheckBox.defaultProps = {
  size: 'md',
  // onChange: () => {},
  checked: false,
  disabled: false,
  updatable: true,
  required: false,
};

export default memo(CheckBox);
