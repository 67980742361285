export const FileOptions = [
  {
    id: 'pdf',
    name: 'PDF',
    label: '.pdf',
    value: 'pdf',
    ext: ['pdf', 'application/pdf'],
  },
  {
    id: 'doc',
    name: 'DOC',
    label: '.doc',
    value: 'doc',
    ext: ['doc'],
  },
  {
    id: 'docx',
    name: 'DOCX',
    label: '.docx',
    value: 'docx',
    ext: [
      'docx',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
  },
  {
    id: 'txt',
    name: 'TXT',
    label: '.txt',
    value: 'txt',
    ext: ['txt'],
  },
  {
    id: 'photo',
    name: 'Photo',
    label: 'photo',
    value: 'photo',
    ext: ['jpg', 'jpeg', 'png', 'image/jpg', 'image/jpeg', 'image/png'],
  },
  {
    id: 'jpg',
    name: 'JPG',
    label: '.jpg',
    value: 'jpg',
    ext: ['jpg', 'image/jpg'],
  },
  {
    id: 'jpeg',
    name: 'JPEG',
    label: '.jpeg',
    value: 'jpeg',
    ext: ['jpeg', 'image/jpeg'],
  },
  {
    id: 'png',
    name: 'PNG',
    label: '.png',
    value: 'png',
    ext: ['png', 'image/png'],
  },
  {
    id: 'gif',
    name: 'GIF',
    label: '.gif',
    value: 'gif',
    ext: ['gif', 'image/gif'],
  },
  {
    id: 'default',
    name: 'Default',
    label: '.*',
    value: 'default',
    ext: ['*'],
  },
];
