type FormStateType =
  | 'builder'
  | 'renderer'
  | 'share'
  | 'results'
  | 'settings'
  | null;

export const FORM_TYPE = {
  REGISTRATION: 'REGISTRATION',
  EVALUATION: 'EVALUATION',
};

export const DEVICE_TYPE = {
  DESKTOP: 'Desktop',
  MOBILE: 'Mobile',
};

export const ROUTE_TYPE: Record<string, FormStateType> = {
  BUILDER: 'builder',
  RENDERER: 'renderer',
};

export const UPLOAD_STATUS = {
  UPLOADING: 'UPLOADING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
};

export const API_STATUS = {
  SUCCESS: 'fulfilled',
  LOADING: 'pending',
};

export const FORM_STATUS = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const ERROR_MESSAGES = {
  UNKNOWN: 'Something went wrong. Please try again.',
  FACE_AUTH: 'File Processing failed. Please try again.',
};

export const FACE_DETECTION_MESSAGES = {
  NO_FACE:
    'No face detected. Please ensure your face is clearly visible and try again.',
  MULTIPLE_FACES:
    'Multiple faces detected. Please capture a photo with only your face.',
  FACE_DETECTED: 'Face detected.',
  FACE_NOT_CLEAR: 'Face not clear. Please try again.',
};

export const VALIDATION_ERROR_MESSAGES = {
  REQUIRED: 'This is a required field',
};
