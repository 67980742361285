import React, { memo } from 'react';
import classNames from 'classnames';
import { TriggerValidationProps } from './types';

const TriggerValidation: React.FC<TriggerValidationProps> = ({
  validationTrigger = false,
  validationMessage = '',
  name,
  otherParameter,
  alterClassName,
}) => {
  const ShowValidation =
    otherParameter !== undefined
      ? validationTrigger && otherParameter && validationMessage
      : validationTrigger && validationMessage;

  const baseClasses = 'font-normal text-xs text-rose-800';
  const finalClassName = alterClassName
    ? classNames(baseClasses, alterClassName)
    : baseClasses;

  return (
    <>
      {ShowValidation && (
        <p
          className={finalClassName}
          id={`${name || 'validation'}-required-error`}
          data-testid="input_error_msg"
        >
          {validationMessage}
        </p>
      )}
    </>
  );
};

export default memo(TriggerValidation);
