import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { AddImage } from '@apps/form/src/images/AddImage';
import { FileUploaderProps } from './types';

const FileUploader: React.FC<FileUploaderProps> = ({
  fileSelected,
  onFileClickFn,
  onClearFile,
  getFileTrailingIcon,
  fileTypeList,
  size,
  disabled,
  updatable,
  pre_filled,
  onFileUpload,
}) => {
  const fileTrailingIcon = useMemo(
    () => getFileTrailingIcon(),
    [getFileTrailingIcon],
  );
  const [isDragging, setIsDragging] = useState(false);
  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => setIsDragging(false);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      onFileUpload({ target: { files: [file] } });
    }
  };
  return (
    <>
      <div
        className={classNames(
          'flex gap-3 rounded-md shadow-sm w-fit border-2 border-dashed p-2 max-w-[25rem] transition-shadow',
          (disabled || (!updatable && pre_filled)) && 'opacity-50',
          isDragging
            ? 'shadow-lg bg-blue-100 border-blue-500'
            : 'border-gray-300',
          'hover:shadow-md hover:bg-blue-50 cursor-pointer',
        )}
        onClick={onFileClickFn}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {!fileSelected?.resource_url && <AddImage />}
        <div className="flex flex-col justify-center gap-1 ">
          <div className={`text-sm font-medium text-blue-600`}>
            Upload a file or drag and drop
          </div>

          <div className="text-left text-gray-500 text-xs font-normal leading-none">
            {!fileSelected ? (
              <div className="w-60 text-gray-500 text-xs font-normal">
                {`${fileTypeList} ${size ? `up to ${size} MB` : ''}`}
              </div>
            ) : (
              `${fileSelected.fileType} ${fileSelected.fileSize || '--'} MB`
            )}
          </div>
        </div>
      </div>
      <div className="justify-start items-center cursor-pointer my-3  bg-blue-50 rounded-md w-full">
        {fileSelected && (
          <div className="flex justify-between gap-2 text-gray-600 text-sm font-medium ">
            <div className="truncate max-w-56 pl-3">
              {fileSelected?.fileName || '--'}
            </div>
            <div
              className="pr-3"
              data-testid="cancel_button"
              onClick={onClearFile}
            >
              {fileTrailingIcon}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploader;
